// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from 'api'
import axios from 'axios'

export const list = createAsyncThunk('appCities/list', async (_, { getState }) => {
  const response = await axios.get(ApiUrl + '/v1/cities')
  const cities = response?.data?.cities ?? []
  const states = getState().states
  const countries = getState().countries
  cities.forEach((e) => {
    e.stateDisplayName = states?.data?.filter((c) => c.name === e.stateName)[0]?.displayName
    e.countryDisplayName = countries?.data?.filter((c) => c.name === e.countryName)[0]?.displayName
  })
  return cities
})

export const appCitiesSlice = createSlice({
  name: 'appCities',
  initialState: {
    data: []
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
  }
})

export default appCitiesSlice.reducer
