// ** Redux Imports
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl, getMetadata } from 'api'
import axios from 'axios'

export const listProfiles = createAsyncThunk('appProfiles/listProfiles', async (params, { dispatch, signal }) => {
  await dispatch(appProfiles.actions.setParams(params))
  const response = await axios.get(ApiUrl + `/v1/profiles?perPage=${params.perPage}&page=${(params.page ?? 1) - 1}`, {
    headers: getMetadata()
  })
  const profiles = response?.data?.profiles ?? []
  return {
    data: profiles,
    params,
    totalRows: parseInt(response?.data?.totalRows),
    totalPages: parseInt(response?.data?.totalPages)
  }
})

export const listAllProfiles = createAsyncThunk('appProfiles/listAllProfiles', async (params, { signal }) => {
  const response = await axios.get(ApiUrl + `/v1/profiles`, {
    headers: getMetadata()
  })
  return response?.data?.profiles ?? []
})

export const listLikes = createAsyncThunk('appProfiles/listInvoices', async () => {
  return []
})

export const getProfile = createAsyncThunk('appProfiles/getProfile', async (name, { dispatch, getState }) => {
  const loaded = (getState().profiles.data ?? []).filter(e => e.name === name)[0]
  if (loaded)
    await dispatch(appProfiles.actions.setSelected(loaded))
  const response = await axios.get(ApiUrl + '/v1/' + name, {
    headers: getMetadata()
  })
  const profile = response?.data
  return profile
})

export const appProfiles = createSlice({
  name: 'appProfiles',
  initialState: {
    data: [],
    allData: [],
    total: 1,
    params: {},
    images: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    },
    setParams: (state, { payload }) => {
      state.params = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(listProfiles.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
        if (state.allData.length > 0) {
          // TODO update
        }
      })
      .addCase(listAllProfiles.fulfilled, (state, { payload }) => {
        state.allData = payload
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appProfiles.reducer
