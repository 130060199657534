// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from 'api'
import axios from 'axios'

export const list = createAsyncThunk('appProducts/list', async (domain) => {
  const response = await axios.get(ApiUrl + `/v1/products?domain=${domain}`)
  const result = {}
  result[domain] = response?.data?.products ?? []
  return result
})

export const appProductsSlice = createSlice({
  name: 'appProducts',
  initialState: {
    data: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = {
          ...state.data,
          ...action.payload
        }
      })
  }
})

export default appProductsSlice.reducer
