// ** Redux Imports
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl, getMetadata } from 'api'
import axios from 'axios'

export const listLikes = createAsyncThunk('appProfileLikesSlice/listLikes', async () => {
  const response = await axios.get(ApiUrl + '/v1/profileLikes', {
    headers: getMetadata()
  })
  const instances = response?.data?.profileLikes ?? []
  return {
    data: instances
  }
})

export const getLikeByProfile = createAsyncThunk('appProfileLikesSlice/listLikes', async (profileName) => {
  const response = await axios.get(ApiUrl + `/v1/profileLikes?profile_name=${profileName}`, {
    headers: getMetadata()
  })
  const instances = response?.data?.profileLikes ?? []
  return {
    data: instances,
  }
})

export const addLike = createAsyncThunk('appProfileLikesSlice/addLike', async (instance, { dispatch }) => {
  await dispatch(appProfileLikesSlice.actions.addProfileLike(instance))
  const response = await axios.post(ApiUrl + '/v1/profileLikes:apply', {
    profileLike: instance
  }, {
    headers: getMetadata()
  })
  const updated = response.data ?? {}
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appProfileLikesSlice.actions.addProfileLike(instance))
  return instance
})

export const deleteLike = createAsyncThunk('appProfileLikesSlice/deleteLike', async (instance, { dispatch }) => {
  await dispatch(appProfileLikesSlice.actions.deleteProfileLike(instance.profileName))
  let name = instance.name

  if (!name) {
    const response = await axios.get(ApiUrl + `/v1/profileLikes?profile_name=${instance.profileName}`, {
      headers: getMetadata()
    })
    name = response?.data?.profileLikes[0]?.name
  }

  if (name) {
    await axios.delete(ApiUrl + `/v1/${name}`, {
    }, {
      headers: getMetadata()
    })
  }
})

export const appProfileLikesSlice = createSlice({
  name: 'appProfileLikes',
  initialState: {
    data: []
  },
  reducers: {
    addProfileLike: (state, { payload }) => {
      state.data = state.data.filter(e => e.profileName !== payload.profileName).concat(payload)
    },
    deleteProfileLike: (state, { payload }) => {
      state.data = state.data.filter(e => e.profileName !== payload)
    }
  },
  extraReducers: builder => {
    builder
      .addCase(listLikes.fulfilled, (state, { payload }) => {
        state.data = payload.data
      })
  }
})

export default appProfileLikesSlice.reducer
