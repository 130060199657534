// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from 'api'
import axios from 'axios'

export const list = createAsyncThunk('appCountries/list', async () => {
  const response = await axios.get(ApiUrl + '/v1/countries')
  return response?.data?.countries
})

export const appCountriesSlice = createSlice({
  name: 'appCountries',
  initialState: {
    data: []
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
  }
})

export default appCountriesSlice.reducer
