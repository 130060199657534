// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl, getMetadata } from 'api'
import axios from 'axios'

export const geoAddress = createAsyncThunk('appUsers/register', async ({ latitude, longitude }) => {
  const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&accept-language=en-EN&format=geocodejson`)
  return (response.data.features.filter(e => e.type === "Feature") ?? [])[0]?.properties.geocoding
})

export const register = createAsyncThunk('appUsers/register', async (user) => {
  const response = await axios.post(ApiUrl + `/v1/users:register`, { user })
  return response
})

export const login = createAsyncThunk('appUsers/login', async ({ login, password }) => {
  const response = await axios.post(ApiUrl + '/v1/users:login', {
    login,
    password
  })
  return response?.data
})

export const confirm = createAsyncThunk('appUsers/confirm', async ({ login, code, source }) => {
  const response = await axios.post(ApiUrl + '/v1/users:confirm', {
    login,
    code,
    source
  })
  return response?.data
})

export const refreshToken = createAsyncThunk('appUsers/refreshToken', async () => {
  const response = await axios.post(ApiUrl + '/v1/users:refreshToken', {
    refreshToken: localStorage.getItem('refreshToken')
  })
  return response?.data
})

export const get = createAsyncThunk('appUsers/get', async () => {
  const response = await axios.get(ApiUrl + '/v1/users/-', {
    headers: getMetadata()
  })
  return response?.data
})

export const addSelfie = createAsyncThunk('appUsers/addSelfie', async ({ id, payload, fileName, contentType }) => {
  const response = await axios.post(ApiUrl + `/v1/files/-/customers/${id}/selfies/${fileName}`, 
    payload,
    {
      headers: {
        ...getMetadata(),
        "Content-Type": contentType
      }
  })
  return response?.data
})

export const modify = createAsyncThunk('appUsers/modify', async (instance, { dispatch }) => {
  const response = await axios.post(ApiUrl + '/v1/users:apply', {
    user: instance
  }, {
    headers: getMetadata()
  })
  const updated = response.data ?? {}
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appUsersSlice.actions.setProfile(instance))
  return instance
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    profile: null
  },
  reducers: {
    setProfile: (state, { payload }) => {
      state.profile = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(get.fulfilled, (state, action) => {
        state.profile = action.payload
      })
  }
})

export default appUsersSlice.reducer
