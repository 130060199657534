// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from 'api'
import axios from 'axios'

export const list = createAsyncThunk('appSubscriptions/list', async (productName) => {
  const response = await axios.get(ApiUrl + `/v1/subscriptions?productName=${productName}`)
  const result = {}
  result[productName] = response?.data?.subscriptions ?? []
  return result
})

export const appSubscriptionsSlice = createSlice({
  name: 'appSubscriptions',
  initialState: {
    data: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = {
          ...state.data,
          ...action.payload
        }
      })
  }
})

export default appSubscriptionsSlice.reducer
