// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl, getFileUrl, getMetadata } from 'api'
import { get as getUser } from '../../../users/store'

// ** Axios Imports
import axios from 'axios'

export const getUserProfile = createAsyncThunk('appChat/getUserProfile', async (_, { dispatch }) => {
  const current = (await dispatch(getUser()))?.payload

  return {
    id: current.name,
    avatar: current.selfieUrl ? getFileUrl(current?.selfieUrl) + '?h=100' :
      require('assets/images/avatars/avatar-blank.png').default,
    displayName: current.displayName,
    status: 'online',
    settings: { }
  }
})

export const getChatContacts = createAsyncThunk('appChat/getChatContacts', async (_, { dispatch }) => {
  const current = (await dispatch(getUser()))?.payload

  let response = await axios.get(ApiUrl + '/v1/rooms', {
    headers: getMetadata()
  })
  const rooms = response?.data?.rooms ?? []

  return { 
    chatsContacts: rooms.map(r => {
      const user = r.users[0]
      return {
        id: user.name.startsWith("profiles/") ? parseInt(user.name.replace("profiles/", "")) : 
          user.name.replace("employees/", ""),
        displayName: user.displayName ? user.displayName : r.displayName,
        avatar: user?.logoUrl ? getFileUrl(user?.logoUrl) + '?h=100' : 
          require('assets/images/avatars/avatar-blank.png').default,
        // status: 'online',
        chat: { 
          id: r.id, 
          unseenMsgs: r.unreadMessages,
          lastMessage: null
        }
      }
    }), 
    contacts: rooms.map(r => {
      const user = r.users[0]
      return {
        id: user.name.startsWith("profiles/") ? parseInt(user.name.replace("profiles/", "")) : 
          user.name.replace("employees/", ""),
        displayName: user.displayName ? user.displayName : r.displayName,
        avatar: user?.logoUrl ? getFileUrl(user?.logoUrl) + '?h=100' : 
          require('assets/images/avatars/avatar-blank.png').default
        // status: 'offline'
      }
    }),
    profileUser: {
      id: current.id,
      avatar: current.logoUrl ? getFileUrl(current?.logoUrl) + '?h=100' :
        require('assets/images/avatars/avatar-blank.png').default,
      displayName: current.displayName,
      status: 'online'
    } 
  }
})

export const selectChat = createAsyncThunk('appChat/selectChat', async (id, { dispatch, getState }) => {
  const defaultContact = defaultContacts.filter(e => e.id === id)[0]
  if (defaultContact) {
    return {
      chat: {
        chat: []
      },
      contact: defaultContact
    }
  }

  let response = await axios.get(ApiUrl + '/v1/rooms', {
    headers: getMetadata()
  })
  const rooms = response?.data?.rooms ?? []
  const room = rooms.filter(r => r.users.filter(u => u.name === `profiles/${id}` || u.name === `employees/${id}`).length)[0]

  if (!room) {
    const contact = getState().chat.contacts.filter(e => e.id === id)[0]
    return {
      chat: {
        chat: []
      },
      contact: {
        id,
        displayName: contact.displayName,
        avatar: contact.avatar
      }
    }
  }

  response = await axios.get(ApiUrl + `/v1/messages?roomName=${room.name}`, {
    headers: getMetadata()
  })

  // await dispatch(getChatContacts())
  return {
    chat: {
      chat: response.data.messages.map(e => ({
        ...e,
        message: e.text,
        time: e.createdAt,
        senderId: e.userName
      })).reverse()
    },
    contact: {
      id,
      displayName: room.users[0].name === "employees/*" ? supportContact.displayName :
        room.users[0].displayName,
      avatar: room.users[0]?.logoUrl ? getFileUrl(room.users[0]?.logoUrl) + '?h=100' : 
        require('assets/images/avatars/avatar-blank.png').default
    }
  }
})

export const addChatByProfile = createAsyncThunk('appChat/addChatByProfile', async (profile, { dispatch }) => {
  await dispatch(appChatSlice.actions.addContact({
    id: profile.id,
    displayName: profile.username,
    avatar: getFileUrl(profile.selfieUrl) + "?h=100",
    status: profile.values?.Connected?.items[0]?.toLowerCase()
  }))
})

export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch, getState }) => {
  let roomName = ''

  if (!obj.contact?.id) {
    const response = await axios.post(ApiUrl + '/v1/rooms:apply', {
      room: {
        displayName: supportContact.displayName,
        users: [{
          name: "employees/*"
        }]
      }
    }, {
      headers: getMetadata()
    })
    roomName = response.data.name
  }

  if (obj.contact?.id === '*') {
    const chatId = getState().chat.supportChats.filter(e => e.id === obj.contact?.id)[0]?.chat?.id
    roomName = `rooms/${chatId}`
  }

  const chatId = getState().chat.chats.filter(e => e.id === obj.contact?.id)[0]?.chat?.id
  if (chatId) {
    roomName = `rooms/${chatId}`
  } else {
    const contactId = getState().chat.contacts.filter(e => e.id === obj.contact?.id)[0]?.id
    if (contactId) {
      const response = await axios.post(ApiUrl + '/v1/rooms:apply', {
        room: {
          displayName: obj.contact.displayName,
          users: [{
            name: `profiles/${obj.contact.id}`
          }]
        }
      }, {
        headers: getMetadata()
      })
  
      roomName = response.data.name
    }
  }

  const response = await axios.post(ApiUrl + '/v1/messages:apply', {
    message: {
      roomName,
      text: obj.message
    }
  }, {
    headers: getMetadata()
  })

  await dispatch(selectChat(obj.contact.id))
  return { 
    newMessageData: {
      ...response.message,
      message: obj.message,
      time: response.message.createdAt,
      senderId: response.message.userName
    }, 
    id: obj.contact.id 
  }
})

const supportContact = {
  id: 0,
  displayName: "Unterstützung",
  avatar: require('assets/images/avatars/avatar-blank.png').default,
  status: 'online'
}

const defaultContacts = [supportContact]

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chats: [],
    contacts: [],
    supportChats: [],
    supportContacts: [],
    userProfile: {},
    selectedUser: {}
  },
  reducers: {
    addContact: (state, { payload }) => {
      if (!state.chats.map(c => c.id).includes(payload.id)) {
        state.contacts = [payload]
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {
        const chats = action.payload.chatsContacts

        state.supportChats = chats.filter(e => e.displayName === supportContact.displayName)

        if (state.supportChats.length) {
          state.supportContacts = []
        } else {
          state.supportContacts = defaultContacts
        }

        state.chats = chats.filter(e => e.displayName !== supportContact.displayName)
        state.contacts = state.contacts.filter(c => !state.chats.map(ch => ch.id).includes(c.id))
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appChatSlice.reducer
