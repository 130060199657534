// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import countries from 'views/locations/countries/store'
import states from 'views/locations/states/store'
import cities from 'views/locations/cities/store'
import users from 'views/users/store'
import profileLikes from 'views/activities/likes/store'
import chat from 'views/activities/chat/store'
import products from 'views/brands/products/store'
import profiles from 'views/profiles/store'
import subscriptions from 'views/brands/subscriptions/store'

const rootReducer = {
  auth,
  countries,
  states,
  cities,
  users,
  products,
  profiles,
  subscriptions,
  chat,
  profileLikes,

  navbar,
  layout
}

export default rootReducer
