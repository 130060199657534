// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiUrl } from 'api'
import axios from 'axios'

export const list = createAsyncThunk('appStates/list', async (_, { getState }) => {
  const response = await axios.get(ApiUrl + '/v1/states')
  const states = response?.data?.states ?? []
  const countries = getState().countries
  states.forEach((e) => {
    e.countryDisplayName = countries?.data?.filter((c) => c.name === e.countryName)[0]?.displayName
  })
  return states
})

export const appStatesSlice = createSlice({
  name: 'appStates',
  initialState: {
    data: []
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
  }
})

export default appStatesSlice.reducer
